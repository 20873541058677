
import {Component, Vue} from 'vue-property-decorator';
import AppTopBar from '@/components/UI/AppTopBar.vue';
import MenuTop from '@/components/MenuTop.vue';
import AppStore from '@/components/AppStore';
import FinesTable from '@/components/fines/FinesTable.vue';
import Http from '@/Http';

@Component({
  components: {FinesTable, MenuTop, AppTopBar}
})

export default class FinesView extends Vue {

  containerWidth = AppStore.containerWidth;

  items: any[] = [];
  loading = false;

  save(option: 'Wp' | 'Email', contactId: number, type: string, enabled: number) {
    AppStore.showLoader();
    Http.post('settings/notices/save' + option, {contactId, type, enabled}).then((response: any) => {
      AppStore.snackbarOpenSuccess('Настройки сохранены.');
    }).finally(() => {
      AppStore.hideLoader();
    }).catch((HttpError) => {
      switch (HttpError.error) {
        default:
          Http.systemError(HttpError.error);
      }
    });
  }

  mounted() {
    AppStore.onContainerWidth(v => this.containerWidth = v);
    this.load();
  }

  load() {
    AppStore.showLoader();
    this.loading = true;
    Http.post('settings/notices').then((response: any) => {
      this.items = response;
    }).finally(() => {
      this.loading = false;
      AppStore.hideLoader();
    }).catch((HttpError) => {
      switch (HttpError.error) {
        default:
          Http.systemError(HttpError.error);
      }
    });
  }

}
